import { FormControl, FormHelperText, FormLabel } from "@chakra-ui/form-control";
import { Tooltip } from "@chakra-ui/react";
import {
  Box,
  Button,
  Checkbox,
  Flex,
  Icon,
  InlineNotification,
  Input,
  Link,
  Select,
  Tag,
  useToast,
  useTranslation,
} from "@familyzone/component-library";
import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import { Link as ReactLink } from "react-router";
import { useCommunityFeatureFlagStore } from "../../storez/CommunityFeatureFlagStore";
import { useDeviceOptionsStore } from "../../storez/DeviceOptionsStore";
import { useFeatureFlagStore } from "../../storez/FeatureFlagStore";
import { Language, useAccountStore } from "../../storez/AccountStore";
import { ResponseError } from "../../types/Api";
import { CommunityFlags, CommunityFlagsEquals } from "../../types/Community";
import { DeviceOptions, DeviceOptionsEquals } from "../../types/Device";
import { FeatureFlags, FeatureFlagsEquals } from "../../types/FeatureFlags";
import SectionHeading from "../header/components/SectionHeading";
import CardBasedPage from "../templates/CardBasedPage";

type LanguageOption = { value: Language; text: string };

const LANGUAGE_OPTIONS: LanguageOption[] = [
  { text: "English UK", value: Language.EN_UK },
  { text: "Debugging", value: Language.EN_XX },
];

enum ChatSupportType {
  ZENDESK = "zendesk",
  SNAPENGAGE = "snapengage",
  NONE = "none",
}

type ChatSupportOption = { text: string; value: ChatSupportType };

const CHAT_SUPPORT_OPTIONS: ChatSupportOption[] = [
  { text: "Zendesk Live Chat (beta)", value: ChatSupportType.ZENDESK },
  { text: "Snap Engage", value: ChatSupportType.SNAPENGAGE },
  { text: "None", value: ChatSupportType.NONE },
];

const ManageDeviceFeatureFlags: React.FC = () => {
  const { t } = useTranslation();
  const { errorToast, successToast } = useToast();

  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);

  const [deviceOptions, setDeviceOptions] = useState<DeviceOptions>({});
  const [communityFlags, setCommunityFlags] = useState<CommunityFlags>({});
  const [featureFlags, setFeatureFlags] = useState<FeatureFlags>({});

  const [accountSettings, setUserLanguage] = useAccountStore(useCallback((state) => [state.settings, state.setLanguage] as const, []));

  const [storedDeviceOptions, fetchDeviceOptions, saveDeviceOptions] = useDeviceOptionsStore(
    useCallback((state) => [state.options, state.fetch, state.save] as const, [])
  );

  const [storedFeatureFlags, fetchFeatureFlags, saveFeatureFlags] = useFeatureFlagStore(
    useCallback((state) => [state.flags, state.fetch, state.save] as const, [])
  );

  const [storedCommunityFlags, fetchCommunityFlags, saveCommunityFlags] = useCommunityFeatureFlagStore(
    useCallback((state) => [state.flags, state.fetch, state.save] as const, [])
  );

  useEffect(() => {
    void Promise.all([
      fetchDeviceOptions().then((options) => setDeviceOptions({ ...options })),
      fetchFeatureFlags().then((flags) => setFeatureFlags({ ...flags })),
      fetchCommunityFlags().then((flags) => setCommunityFlags({ ...flags })),
    ]).then(
      () => {
        setLoading(false);
      },
      (err: ResponseError) => {
        errorToast({
          title: t("Please try again"),
          description: t(err.message),
          isClosable: true,
        });
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchDeviceOptions, fetchFeatureFlags, fetchCommunityFlags]);

  const handleDeviceOptionsChange = (event: ChangeEvent<HTMLInputElement>) => {
    const name = event.target.id;
    const value = event.target.checked;
    setDeviceOptions({ ...deviceOptions, [name]: value });
  };

  const handleCommunityFlagsChange = (event: ChangeEvent<HTMLInputElement>) => {
    const name = event.target.id;
    const value = event.target.checked;

    if (name === "enableCommunity" && !value) {
      setCommunityFlags({
        ...communityFlags,
        enableCommunity: false,
        enableCommunityDashboard: false,
        enableDelegation: false,
        enableDelegationByInclusion: false,
      });
    } else if (name === "enableDelegation" && !value) {
      setCommunityFlags({
        ...communityFlags,
        enableDelegation: false,
        enableDelegationByInclusion: false,
      });
    } else {
      setCommunityFlags({ ...communityFlags, [name]: value });
    }
  };

  const handleFeatureFlagsChange = (event: ChangeEvent<HTMLInputElement>) => {
    const name = event.target.id;
    const value = event.target.checked;

    if (name === "companion-mode-enabled" && !value) {
      setFeatureFlags({
        ...featureFlags,
        "companion-mode-enabled": false,
        "companion-mode-auto-policies": false,
        "companion-mode-lite-enabled": false,
        "deprecate-feature-flag-auto-set-policies": false,
        qoria_dns: false,
      });
    } else {
      setFeatureFlags({ ...featureFlags, [name]: value });
    }
  };

  const handleLanguageChange = (selected: LanguageOption | null) => {
    if (selected) {
      setUserLanguage(selected.value);
    }
  };

  const handleChatSupportChange = (selected: ChatSupportOption | null) => {
    setDeviceOptions((options) => ({
      ...options,
      allow_support_zendesk: selected?.value === ChatSupportType.ZENDESK,
      allow_support_school_manager: selected?.value === ChatSupportType.SNAPENGAGE,
    }));
  };

  const handleSave = () => {
    setSaving(true);

    const promises = [];

    if (!FeatureFlagsEquals(featureFlags, storedFeatureFlags)) {
      promises.push(saveFeatureFlags(featureFlags).then((saved) => setFeatureFlags(saved)));
    }

    if (!DeviceOptionsEquals(deviceOptions, storedDeviceOptions)) {
      // device options API supports partial update => save only the
      // properties that can be changed on the feature flags page
      promises.push(
        saveDeviceOptions({
          display_machine_name: deviceOptions.display_machine_name ?? false,
          allow_extension_connections: deviceOptions.allow_extension_connections ?? false,
          allow_support_school_manager: deviceOptions.allow_support_school_manager ?? false,
          allow_support_zendesk: deviceOptions.allow_support_zendesk ?? false,
        }).then((saved) => setDeviceOptions(saved))
      );
    }

    if (!CommunityFlagsEquals(communityFlags, storedCommunityFlags)) {
      promises.push(saveCommunityFlags(communityFlags).then((saved) => setCommunityFlags(saved)));
    }

    void Promise.all(promises)
      .then(
        () => {
          successToast({
            title: t("Update successful"),
            description: t("Feature flags have been successfully updated"),
            isClosable: true,
          });
        },
        (err: ResponseError) => {
          errorToast({
            title: t("Please try again"),
            description: t(err.message),
            isClosable: true,
          });
        }
      )
      .finally(() => setSaving(false));
  };

  const selectedLanguageOption = useMemo(() => {
    return LANGUAGE_OPTIONS.find((o) => o.value === accountSettings.language);
  }, [accountSettings]);

  const selectedChatSupportOption = useMemo(() => {
    if (Object.keys(deviceOptions).length === 0) return undefined;

    let chatSupportType = ChatSupportType.NONE;
    if (deviceOptions.allow_support_zendesk) {
      chatSupportType = ChatSupportType.ZENDESK;
    } else if (deviceOptions.allow_support_school_manager) {
      chatSupportType = ChatSupportType.SNAPENGAGE;
    }
    return CHAT_SUPPORT_OPTIONS.find((o) => o.value === chatSupportType);
  }, [deviceOptions]);

  const hasChanges =
    (Object.keys(deviceOptions).length > 0 && !DeviceOptionsEquals(deviceOptions, storedDeviceOptions)) ||
    (Object.keys(featureFlags).length > 0 && !FeatureFlagsEquals(featureFlags, storedFeatureFlags)) ||
    (Object.keys(communityFlags).length > 0 && !CommunityFlagsEquals(communityFlags, storedCommunityFlags));

  return (
    <CardBasedPage
      title={t("Feature Flags (Support Admin Only)")}
      breadcrumbs={[
        { title: t("Debugging"), isActive: false },
        { title: t("Feature Flags"), isActive: true },
      ]}
    >
      <Box>
        <Box p="sp24">
          <Box>
            <SectionHeading title={t("Miscellaneous")} />

            <Flex px="sp12" py="sp16" direction="column" gap="sp16">
              <FormControl isDisabled={loading || saving}>
                <Checkbox
                  id="display_machine_name"
                  isChecked={deviceOptions.display_machine_name}
                  isDisabled={loading || saving}
                  onChange={handleDeviceOptionsChange}
                >
                  {t("Display Machine Name")}
                </Checkbox>
                <FormHelperText ml="sp24" color="neutrals.200">
                  {t("Displays 'Machine Name' column on Statistics > Users > User Timeline page.")}
                </FormHelperText>
              </FormControl>

              <FormControl isDisabled={loading || saving}>
                <Checkbox
                  id="show-https-inspection-menu-item"
                  isChecked={featureFlags["show-https-inspection-menu-item"]}
                  isDisabled={loading || saving}
                  onChange={handleFeatureFlagsChange}
                >
                  {t("Show HTTPS Inspection Page")}
                </Checkbox>
                <FormHelperText ml="sp24" color="neutrals.200">
                  {t("Displays Configuration > Networking > HTTPS Inspection menu item.")}
                </FormHelperText>
              </FormControl>

              <FormControl isDisabled={loading || saving}>
                <Checkbox
                  id="allow_extension_connections"
                  isChecked={deviceOptions.allow_extension_connections}
                  isDisabled={loading || saving}
                  onChange={handleDeviceOptionsChange}
                >
                  {t("Browser Extension Connection Reporting")}
                </Checkbox>
                <FormHelperText ml="sp24" color="neutrals.200">
                  {t("Allows connection information while inside network.")}
                </FormHelperText>
              </FormControl>

              <FormControl isDisabled={loading || saving}>
                <Checkbox
                  id="filtering_agents_custom_http_headers"
                  isChecked={featureFlags.filtering_agents_custom_http_headers}
                  isDisabled={loading || saving}
                  onChange={handleFeatureFlagsChange}
                >
                  {t("Enable Custom Headers (Product Initiative)")}
                </Checkbox>
                <FormHelperText ml="sp24" color="neutrals.200">
                  {t("Enables Mac agents to inject custom headers specified in 'allow' filtering policies.")}
                </FormHelperText>
              </FormControl>

              <FormControl isDisabled={loading || saving}>
                <Checkbox
                  id="config_agents_filtering_bypass"
                  isChecked={featureFlags.config_agents_filtering_bypass}
                  isDisabled={loading || saving}
                  onChange={handleFeatureFlagsChange}
                >
                  {t("Bypass Filtering")}
                </Checkbox>
                <FormHelperText ml="sp24" color="neutrals.200">
                  {t("Displays 'Bypass Filtering Groups' option on Configuration > Mobile Agent page.")}
                </FormHelperText>
              </FormControl>

              <FormControl isDisabled={loading || saving}>
                <Checkbox
                  id="device_specs"
                  isChecked={featureFlags.device_specs}
                  isDisabled={loading || saving}
                  onChange={handleFeatureFlagsChange}
                >
                  {t("Device Specs")}
                </Checkbox>
                <FormHelperText ml="sp24" color="neutrals.200">
                  {t("Enables Windows and Mac agents to include hardware information in connection reports.")}
                </FormHelperText>
              </FormControl>

              <FormControl isDisabled={loading || saving}>
                <Checkbox
                  id="show-manage-user-password"
                  isChecked={featureFlags["show-manage-user-password"]}
                  isDisabled={loading || saving}
                  onChange={handleFeatureFlagsChange}
                >
                  {t("Show Password Field for Local Users")}
                </Checkbox>
                <FormHelperText ml="sp24" color="neutrals.200">
                  {t("Displays 'Set Password' button for local users on Configuration > Users > Manage User page.")}
                </FormHelperText>
              </FormControl>

              <FormControl isDisabled={loading || saving}>
                <Flex alignItems="center" gap="sp8">
                  <Checkbox
                    id="configure_restrictive_mode"
                    isChecked={featureFlags.configure_restrictive_mode}
                    isDisabled={loading || saving}
                    onChange={handleFeatureFlagsChange}
                  >
                    {t("Configure Captive Portal Restrictive Mode")}
                  </Checkbox>
                  <Tag size="compact" variant="orange" tagLabel={t("Dev Only")} />
                </Flex>
                <FormHelperText ml="sp24" color="neutrals.200">
                  {t("Displays 'Restrictive Mode' option on Configuration > Authentication > Captive Portal page.")}
                </FormHelperText>
              </FormControl>

              <FormControl isDisabled={loading || saving}>
                <Checkbox
                  id="hide-red-flags"
                  isChecked={featureFlags["hide-red-flags"]}
                  isDisabled={loading || saving}
                  onChange={handleFeatureFlagsChange}
                >
                  {t("Hide Red Flags")}
                </Checkbox>
                <FormHelperText ml="sp24" color="neutrals.200">
                  {t("Hides 'Red Flags' section on Statistics > Dashboard page and Cyber Safety > Red Flags menu item.")}
                </FormHelperText>
              </FormControl>

              <FormControl isDisabled={loading || saving}>
                <Checkbox
                  id="hide-dashboard-teacher-signin"
                  isChecked={featureFlags["hide-dashboard-teacher-signin"]}
                  isDisabled={loading || saving}
                  onChange={handleFeatureFlagsChange}
                >
                  {t("Hide Teacher Sign In button on Dashboard")}
                </Checkbox>
                <FormHelperText ml="sp24" color="neutrals.200">
                  {t("Hides 'Sign In as Teacher' button on Statistics > Dashboard page.")}
                </FormHelperText>
              </FormControl>

              <FormControl isDisabled={loading || saving}>
                <Checkbox
                  id="show-clever-sync-menu-item"
                  isChecked={featureFlags["show-clever-sync-menu-item"]}
                  isDisabled={loading || saving}
                  onChange={handleFeatureFlagsChange}
                >
                  {t("Enable Clever Sync Menu")}
                </Checkbox>
                <FormHelperText ml="sp24" color="neutrals.200">
                  {t("Show 'Clever' section on Configuration > Authentication > Clever")}
                </FormHelperText>
              </FormControl>
            </Flex>
          </Box>
          {/* <!--./Miscellaneous--> */}

          <Box>
            <SectionHeading title={t("Chat Support")} />

            <Flex px="sp12" py="sp16" direction="column" gap="sp16">
              <FormControl isDisabled={loading || saving}>
                <Flex gap="sp8">
                  <FormLabel mt="sp12" htmlFor="chatSupport-input">
                    {t("Chat Support")}
                  </FormLabel>
                  <Flex direction="column">
                    {selectedChatSupportOption && !loading ? (
                      <Select
                        id="chatSupport"
                        items={CHAT_SUPPORT_OPTIONS}
                        selectedItem={selectedChatSupportOption}
                        onChange={(e) => handleChatSupportChange(e as ChatSupportOption)}
                        disabled={loading || saving}
                      />
                    ) : (
                      /* Select component does not refresh the initial selected value once it's set, */
                      /* so as a workaround, display a placeholder box until the value is available */
                      <Input isDisabled={true} placeholder={selectedChatSupportOption?.text} />
                    )}
                    <FormHelperText mt="sp8" color="neutrals.200">
                      {t("Displays the selected support widget in bottom-right corner for all users of this device.")}
                    </FormHelperText>
                  </Flex>
                </Flex>
              </FormControl>
            </Flex>
          </Box>
          {/* <!--./Chat Support--> */}

          <Box>
            <SectionHeading title={t("Classwize")} />

            <Flex px="sp12" py="sp16" direction="column" gap="sp16">
              <FormControl isDisabled={loading || saving}>
                <Checkbox
                  id="agent-p2p-screenshots"
                  isChecked={featureFlags["agent-p2p-screenshots"]}
                  isDisabled={loading || saving}
                  onChange={handleFeatureFlagsChange}
                >
                  {t("Live View for Windows and Mac")}
                </Checkbox>
                <FormHelperText ml="sp24" color="neutrals.200">
                  {t("Enables Live View in Classwize for Windows and Mac agents.")}
                </FormHelperText>
              </FormControl>

              <FormControl isDisabled={loading || saving}>
                <Checkbox
                  id="classwize-teacher-student-chat"
                  isChecked={featureFlags["classwize-teacher-student-chat"]}
                  isDisabled={loading || saving}
                  onChange={handleFeatureFlagsChange}
                >
                  {t("Teacher-Student Two-Way Chat")}
                </Checkbox>
                <FormHelperText ml="sp24" color="neutrals.200">
                  {t("Chromebooks and Windows Companion mode only.")}
                </FormHelperText>
              </FormControl>

              <FormControl isDisabled={loading || saving}>
                <Checkbox
                  id="shr-enabled"
                  isChecked={featureFlags["shr-enabled"]}
                  isDisabled={loading || saving}
                  onChange={handleFeatureFlagsChange}
                >
                  {t("Screenshot History Report")}
                </Checkbox>
                <FormHelperText ml="sp24" color="neutrals.200">
                  {t("Enables collection of screenshot history and 'Screenshot History' report in Classwize.")}
                </FormHelperText>
              </FormControl>
            </Flex>
          </Box>
          {/* <!--./Classwize--> */}

          <Box>
            <SectionHeading title={t("Connection Search Report")} />

            <Flex px="sp12" py="sp16" direction="column" gap="sp16">
              <FormControl isDisabled={loading || saving}>
                <Checkbox
                  id="connection-search-report"
                  isChecked={featureFlags["connection-search-report"]}
                  isDisabled={loading || saving}
                  onChange={handleFeatureFlagsChange}
                >
                  {t("Connection Search Report")}
                </Checkbox>
                <FormHelperText ml="sp24" color="neutrals.200">
                  {t("Displays top-level Reports menu item.")}
                </FormHelperText>
              </FormControl>
            </Flex>
          </Box>
          {/* <!--./Connection Search Report--> */}

          <Box>
            <SectionHeading title={t("Student Reports")} />

            <Flex px="sp12" py="sp16" direction="column" gap="sp16">
              <FormControl isDisabled={loading || saving}>
                <Checkbox
                  id="show-student-wellbeing-menu-item"
                  isChecked={featureFlags["show-student-wellbeing-menu-item"]}
                  isDisabled={loading || saving}
                  onChange={handleFeatureFlagsChange}
                >
                  {t("Show Student Wellbeing Report")}
                </Checkbox>
                <FormHelperText ml="sp24" color="neutrals.200">
                  {t("Displays Reports > Student Wellbeing menu item.")}
                </FormHelperText>
              </FormControl>

              <FormControl isDisabled={loading || saving}>
                <Checkbox
                  id="show-student-behaviour-menu-item"
                  isChecked={featureFlags["show-student-behaviour-menu-item"]}
                  isDisabled={loading || saving}
                  onChange={handleFeatureFlagsChange}
                >
                  {t("Show Student Behaviour Report")}
                </Checkbox>
                <FormHelperText ml="sp24" color="neutrals.200">
                  {t("Displays Reports > Student Behaviour menu item.")}
                </FormHelperText>
              </FormControl>

              <FormControl isDisabled={loading || saving}>
                <Checkbox
                  id="show-student-activity-menu-item"
                  isChecked={featureFlags["show-student-activity-menu-item"]}
                  isDisabled={loading || saving}
                  onChange={handleFeatureFlagsChange}
                >
                  {t("Show Student Activity Report")}
                </Checkbox>
                <FormHelperText ml="sp24" color="neutrals.200">
                  {t("Displays Reports > Student Activity menu item.")}
                </FormHelperText>
              </FormControl>

              <FormControl isDisabled={loading || saving}>
                <Checkbox
                  id="show-distracted-student-menu-item"
                  isChecked={featureFlags["show-distracted-student-menu-item"]}
                  isDisabled={loading || saving}
                  onChange={handleFeatureFlagsChange}
                >
                  {t("Show Distracted Student Report")}
                </Checkbox>
                <FormHelperText ml="sp24" color="neutrals.200">
                  {t("Displays Reports > Digital Distraction menu item.")}
                </FormHelperText>
              </FormControl>

              <FormControl isDisabled={loading || saving}>
                <Checkbox
                  id="show-activity-compliance-menu-item"
                  isChecked={featureFlags["show-activity-compliance-menu-item"]}
                  isDisabled={loading || saving}
                  onChange={handleFeatureFlagsChange}
                >
                  {t("Show Activity and Compliance Report")}
                </Checkbox>
                <FormHelperText ml="sp24" color="neutrals.200">
                  {t("Displays Reports > Activity and Compliance menu item.")}
                </FormHelperText>
              </FormControl>

              <FormControl isDisabled={loading || saving}>
                <Checkbox
                  id="show-applications-usage-menu-item"
                  isChecked={featureFlags["show-applications-usage-menu-item"]}
                  isDisabled={loading || saving}
                  onChange={handleFeatureFlagsChange}
                >
                  {t("Show Applications Usage Report")}
                </Checkbox>
                <FormHelperText ml="sp24" color="neutrals.200">
                  {t("Displays Reports > Applications Usage menu item.")}
                </FormHelperText>
              </FormControl>
            </Flex>
          </Box>
          {/* <!--./Student Reports--> */}

          <Box>
            <SectionHeading title={t("Community")} />

            <Flex px="sp12" py="sp16" direction="column" gap="sp16">
              <FormControl isDisabled={loading || saving || (!communityFlags.enableCommunity && !deviceOptions.display_name)}>
                <Checkbox
                  id="enableCommunity"
                  isChecked={communityFlags.enableCommunity}
                  isDisabled={loading || saving || (!communityFlags.enableCommunity && !deviceOptions.display_name)}
                  onChange={handleCommunityFlagsChange}
                >
                  {t("Enable Community")}
                </Checkbox>
                <FormHelperText ml="sp24" color="neutrals.200">
                  {t("Displays Configuration > Community menu item, allows importing parent data, and enables Qustodio integration.")}
                </FormHelperText>

                {!loading && !communityFlags.enableCommunity && !deviceOptions.display_name && (
                  <Box mt="sp8" ml="sp24" width="fit-content">
                    <InlineNotification
                      status="info"
                      notificationDescription={
                        <Box pr="sp8" color="text.title">
                          {t("Community cannot be enabled until an Organisation Name is added in")}{" "}
                          <Link as={ReactLink} to="/managedevice/settings/device">
                            {t("Debugging > Device Options")}
                          </Link>
                          {"."}
                        </Box>
                      }
                    />
                  </Box>
                )}

                {!loading && communityFlags.enableCommunity && !deviceOptions.display_name && (
                  <Box mt="sp8" ml="sp24" width="fit-content">
                    <InlineNotification
                      status="warning"
                      notificationDescription={
                        <Box pr="sp8" color="text.title">
                          {t("Community requires Organisation Name to be set in Debugging > Device Options.")}
                        </Box>
                      }
                    />
                  </Box>
                )}
              </FormControl>

              <FormControl isDisabled={loading || saving || (!communityFlags.enableCommunity && !communityFlags.enableCommunityDashboard)}>
                <Checkbox
                  id="enableCommunityDashboard"
                  isChecked={communityFlags.enableCommunityDashboard}
                  isDisabled={loading || saving || (!communityFlags.enableCommunity && !communityFlags.enableCommunityDashboard)}
                  onChange={handleCommunityFlagsChange}
                >
                  {t("Enable Community Dashboard")}
                </Checkbox>
                <FormHelperText ml="sp24" color="neutrals.200">
                  {t("Displays Configuration > Community > Dashboard menu item.")}
                </FormHelperText>
              </FormControl>

              <FormControl isDisabled={loading || saving || !communityFlags.enableCommunity || storedCommunityFlags?.enableWeeklyCampaign}>
                <Checkbox
                  id="enableWeeklyCampaign"
                  isChecked={communityFlags.enableWeeklyCampaign}
                  isDisabled={loading || saving || !communityFlags.enableCommunity || storedCommunityFlags?.enableWeeklyCampaign}
                  onChange={handleCommunityFlagsChange}
                >
                  {t("Enable Weekly Campaign")}
                </Checkbox>
                <FormHelperText ml="sp24" color="neutrals.200">
                  {t("Displays 'Send Weekly Reports' option on Configuration > Community > Configure Community page.")}
                </FormHelperText>
                {!loading && (communityFlags.enableCommunity || communityFlags.enableWeeklyCampaign) && (
                  <Box mt="sp8" ml="sp24" width="fit-content">
                    <InlineNotification
                      status="info"
                      notificationDescription={
                        <Box pr="sp8" color="text.title">
                          {t(
                            "Once the Weekly Campaign feature flag is enabled, it cannot be disabled by the user. Contact Community PM to disable it."
                          )}
                        </Box>
                      }
                    />
                  </Box>
                )}
              </FormControl>

              <FormControl isDisabled={loading || saving || (!communityFlags.enableCommunity && !communityFlags.enableDelegation)}>
                <Checkbox
                  id="enableDelegation"
                  isChecked={communityFlags.enableDelegation}
                  isDisabled={loading || saving || (!communityFlags.enableCommunity && !communityFlags.enableDelegation)}
                  onChange={handleCommunityFlagsChange}
                >
                  {t("Enable Parental Controls")}
                </Checkbox>
                <FormHelperText ml="sp24" color="neutrals.200">
                  {t("Displays 'Parental Controls' section on Configuration > Community > Configure Community page.")}
                </FormHelperText>
              </FormControl>

              <FormControl isDisabled={loading || saving || !communityFlags.enableDelegation}>
                <Checkbox
                  id="enableDelegationByInclusion"
                  isChecked={communityFlags.enableDelegationByInclusion}
                  isDisabled={loading || saving || !communityFlags.enableDelegation}
                  onChange={handleCommunityFlagsChange}
                >
                  {t("Enable Parental Controls By Inclusion")}
                </Checkbox>
                <FormHelperText ml="sp24" color="neutrals.200">
                  {t(
                    "Limits 'Parental Controls' availability to users and/or groups selected on Configuration > Community > Configure Community page."
                  )}
                </FormHelperText>
              </FormControl>
            </Flex>
          </Box>
          {/* <!--./Community--> */}

          <Box>
            <SectionHeading title={t("Filtering")} />

            <Flex px="sp12" py="sp16" direction="column" gap="sp16">
              <FormControl isDisabled={loading || saving}>
                <Checkbox
                  id="show-cloudsafe-menu-item"
                  isChecked={featureFlags["show-cloudsafe-menu-item"]}
                  isDisabled={loading || saving}
                  onChange={handleFeatureFlagsChange}
                >
                  {t("Show Cloudsafe Network Page")}
                </Checkbox>
                <FormHelperText ml="sp24" color="neutrals.200">
                  {t("Displays Filtering > Cloud Safe Network menu item.")}
                </FormHelperText>
              </FormControl>

              <FormControl isDisabled={loading || saving}>
                <Flex alignItems="center" gap="sp8">
                  <Checkbox
                    id="companion-mode-enabled"
                    isChecked={featureFlags["companion-mode-enabled"]}
                    isDisabled={loading || saving}
                    onChange={handleFeatureFlagsChange}
                  >
                    {t("Enable Companion Mode")}
                  </Checkbox>
                  <Tag size="compact" variant="orange" tagLabel={t("Not For Customers")} />
                </Flex>
                <FormHelperText ml="sp24" color="neutrals.200">
                  {t("Displays 'Companion' filtering mode option on Configuration > Mobile Agent page.")}
                </FormHelperText>
              </FormControl>

              {featureFlags["companion-mode-enabled"] && (
                <>
                  <FormControl isDisabled={loading || saving}>
                    <Flex alignItems="center" gap="sp8">
                      <Checkbox
                        id="companion-mode-auto-policies"
                        isChecked={featureFlags["companion-mode-auto-policies"]}
                        isDisabled={loading || saving}
                        onChange={handleFeatureFlagsChange}
                      >
                        {t("Companion Mode Auto Set Policies")}
                      </Checkbox>
                      <Tag size="compact" variant="orange" tagLabel={t("Not For Customers")} />
                    </Flex>
                    <FormHelperText ml="sp24" color="neutrals.200">
                      {t("Allows automatic policy updates for Companion Mode deployments.")}
                    </FormHelperText>
                  </FormControl>

                  <FormControl isDisabled={loading || saving}>
                    <Flex alignItems="center" gap="sp8">
                      <Checkbox
                        id="deprecate-feature-flag-auto-set-policies"
                        isChecked={featureFlags["deprecate-feature-flag-auto-set-policies"]}
                        isDisabled={loading || saving}
                        onChange={handleFeatureFlagsChange}
                      >
                        {t("Deprecate Companion auto set feature flag")}
                      </Checkbox>
                      <Tag size="compact" variant="orange" tagLabel={t("Not For Customers")} />
                    </Flex>
                    <FormHelperText ml="sp24" color="neutrals.200">
                      {t("Deprecates feature flag based Companion mode auto set policy.")}
                    </FormHelperText>
                  </FormControl>

                  <FormControl isDisabled={loading || saving}>
                    <Flex alignItems="center" gap="sp8">
                      <Checkbox
                        id="companion-mode-lite-enabled"
                        isChecked={featureFlags["companion-mode-lite-enabled"]}
                        isDisabled={loading || saving}
                        onChange={handleFeatureFlagsChange}
                      >
                        {t("Companion Mode Lite")}
                      </Checkbox>
                      <Tag size="compact" variant="orange" tagLabel={t("Troubleshooting Only")} />
                    </Flex>
                    <FormHelperText ml="sp24" color="neutrals.200">
                      {t("Disables DNS filtering agent plugin when in Companion Mode.")}
                    </FormHelperText>
                  </FormControl>

                  <FormControl isDisabled={loading || saving}>
                    <Flex alignItems="center" gap="sp8">
                      <Checkbox
                        id="qoria_dns"
                        isChecked={featureFlags["qoria_dns"]}
                        isDisabled={loading || saving}
                        onChange={handleFeatureFlagsChange}
                      >
                        {t("Qoria DNS")}
                      </Checkbox>
                      <Tag size="compact" variant="brand" tagLabel={t("Companion Mode Only")} />
                    </Flex>
                    <FormHelperText ml="sp24" color="neutrals.200">
                      {t("Uses Qoria DNS as fallback filtering solution when in Companion Mode.")}
                    </FormHelperText>
                  </FormControl>
                </>
              )}
            </Flex>
          </Box>
          {/* <!--./Filtering--> */}

          <Box>
            <SectionHeading
              title={t("Experimental UI/UX")}
              icon={
                <Tooltip
                  variant="dark"
                  placement="top-start"
                  hasArrow={true}
                  maxWidth="336px"
                  label={t("The experimental options may not properly function, or not function at all. Caution is advised.")}
                  offset={[-16, 8]}
                >
                  <Flex>
                    <Icon variant="solid" color="brand.400" fontSize="lg" icon="fa-info-circle" />
                  </Flex>
                </Tooltip>
              }
            />

            <Flex px="sp12" py="sp16" direction="column" gap="sp16">
              <FormControl isDisabled={loading || saving}>
                <Flex gap="sp8">
                  <FormLabel mt="sp12" htmlFor="userLanguage-input">
                    {t("Language")}
                  </FormLabel>
                  <Flex direction="column">
                    <Flex gap="sp8">
                      {selectedLanguageOption && !loading ? (
                        <Select
                          id="userLanguage"
                          items={LANGUAGE_OPTIONS}
                          selectedItem={selectedLanguageOption}
                          onChange={(e) => handleLanguageChange(e as LanguageOption)}
                          disabled={loading || saving}
                        />
                      ) : (
                        /* Select component does not refresh the initial selected value once it's set, */
                        /* so as a workaround, display a placeholder box until the value is available */
                        <Input isDisabled={true} placeholder={selectedLanguageOption?.text} />
                      )}
                      <Box mt="sp8">
                        <Tag size="compact" variant="orange" tagLabel={t("Dev Only")} />
                      </Box>
                    </Flex>
                    <FormHelperText mt="sp8" color="neutrals.200">
                      {t("Changes the language of the app for the current user.")}
                    </FormHelperText>
                  </Flex>
                </Flex>
              </FormControl>
            </Flex>
          </Box>
          {/* <!--./Experimental UI/UX--> */}
        </Box>
        <Box p="sp24" backgroundColor="brand.100" borderBottomRadius="sm">
          <Button variant="primary" disabled={loading || saving || !hasChanges} onClick={handleSave}>
            {saving ? t("Saving..") : hasChanges ? t("Save") : t("Saved")}
          </Button>
        </Box>
      </Box>
    </CardBasedPage>
  );
};

export default ManageDeviceFeatureFlags;

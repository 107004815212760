/**
 * Compares two objects of the same type to determine if they are equal.
 *
 * This function performs a deep equality check to compare the properties of
 * the two objects. It returns `true` if both objects have the same properties
 * with the same values, otherwise it returns `false`.
 *
 * @param {T | undefined} o1 - The first object to compare.
 * @param {T | undefined} o2 - The second object to compare.
 * @returns {boolean} - `true` if the objects are equal, `false` otherwise.
 *
 * @template T - The type of the objects being compared.
 */
export const objectsEqual = <T>(o1?: T, o2?: T): boolean => {
  if (o1 === o2) {
    return true;
  }

  if (!o1 || !o2 || typeof o1 !== "object" || typeof o2 !== "object") {
    return false;
  }

  const keys1 = Object.keys(o1);
  const keys2 = Object.keys(o2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    const typedKey = key as keyof T;
    if (!objectsEqual(o1[typedKey], o2[typedKey])) {
      return false;
    }
  }

  return true;
};

import React, { ChangeEvent, useCallback, useEffect, useState } from "react";
import { Box, Button, Checkbox, InlineNotification, Tag, Text, useToast, useTranslation } from "@familyzone/component-library";
import { Flex } from "@chakra-ui/layout";
import { Img } from "@chakra-ui/image";
import { Link } from "@familyzone/component-library";
import WindowsLogo from "../../../images/Windows_logo_-_2012.svg";
import MacLogo from "../../../images/Apple_logo_black.svg";
import ChromebookLogo from "../../../images/Google_Chrome_icon_February_2022.svg";
import SessionStore from "../../stores/SessionStore";
import { useAgentAuthStore } from "../../stores/AgentAuthStore";
import { useFeatureFlagStore } from "../../storez/FeatureFlagStore";
import { ResponseError } from "../../types/Api";
import { FormControl, FormLabel } from "@chakra-ui/form-control";
import { useAgentConfigStore } from "../../storez/AgentConfigStore";
import { objectsEqual } from "../../utils/EqualityUtils";
import CardBasedPage from "../templates/CardBasedPage";
import { SectionHeading } from "../shared/SectionHeading";
import { AutoSetPoliciesConfig, AgentConfig } from "../../types/AgentConfig";

const MobileAgentDownloads: React.FC = () => {
  const { t } = useTranslation();
  const { successToast, errorToast } = useToast();

  const [agentAuth, fetchAgentAuth] = useAgentAuthStore(useCallback((state) => [state.agentAuth, state.getAgentAuth] as const, []));
  const [featureFlags, getOrFetchFeatureFlags] = useFeatureFlagStore(useCallback((state) => [state.flags, state.getOrFetch] as const, []));
  const [storedAgentConfig, getOrFetchAgentConfig, setAgentConfig, saveAgentConfig] = useAgentConfigStore(
    (state) => [state.config, state.getOrFetch, state.set, state.save] as const
  );
  const [autoSetPoliciesConfig, setAutoSetPoliciesConfig] = useState<AutoSetPoliciesConfig>({
    companion: { enabled: false },
    content_aware: { enabled: false },
  });
  const [loading, setLoading] = React.useState<boolean>(false);
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    void Promise.all([fetchAgentAuth(), getOrFetchFeatureFlags(), getOrFetchAgentConfig()])
      .then(([, , AgentConfig]) => {
        setAutoSetPoliciesConfig(AgentConfig.auto_set_policies || {});
      })
      .catch((err: ResponseError) => {
        errorToast({
          title: t("Please try again"),
          description: t(err?.message ?? "Failed to fetch auto update policy config"),
          isClosable: true,
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchAgentAuth, getOrFetchFeatureFlags, getOrFetchAgentConfig]);

  const copyToClipboard = async (text: string) => {
    if (!navigator?.clipboard) {
      console.warn("Clipboard not supported");
      errorToast({
        title: t("Failed to Copy"),
        description: t("Clipboard not supported"),
        isClosable: true,
      });
      return;
    }

    try {
      await navigator.clipboard.writeText(text);
      successToast({
        title: t("Success"),
        description: "Copied!",
        isClosable: true,
      });
    } catch (error) {
      console.warn("Copy failed", error);
    }

    await navigator.clipboard.writeText(text);
  };

  const getRegion = (region: string) => {
    if (region === "us-1") {
      return "syd-1";
    }

    return region;
  };

  /** Handles form submission */
  const handleSubmit = () => {
    if (storedAgentConfig) {
      setSaving(true);

      const agentConfig = {
        ...storedAgentConfig,
        auto_set_policies: {
          ...storedAgentConfig.auto_set_policies,
          ...autoSetPoliciesConfig,
        },
      };
      if (!objectsEqual<AgentConfig>(agentConfig, storedAgentConfig)) {
        saveAgentConfig(agentConfig)
          .then((saved) => {
            setAgentConfig(agentConfig || {});
            setAutoSetPoliciesConfig(saved.auto_set_policies || {});
          })
          .then(
            () => {
              successToast({
                title: t("Update successful"),
                description: t("Auto update policy config has been saved successfully."),
                isClosable: true,
              });
            },
            (err: ResponseError) => {
              errorToast({
                title: t("Please try again"),
                description: t(err.message),
                isClosable: true,
              });
            }
          )
          .finally(() => setSaving(false));
      }

      setLoading(false);
    }
  };

  /** Updates the auto update policy config state */
  const handleAgentConfigChange = (event: ChangeEvent<HTMLInputElement>) => {
    const name = event.target.id as keyof AutoSetPoliciesConfig;
    const value = event.target.checked;

    if (autoSetPoliciesConfig) {
      setAutoSetPoliciesConfig({
        ...autoSetPoliciesConfig,
        ...{
          [name]: {
            enabled: value,
          },
        },
      });
    }
  };

  /** Checks for new form field entries to be saved */
  const hasChanges =
    Object.keys(autoSetPoliciesConfig) && !objectsEqual<AutoSetPoliciesConfig>(autoSetPoliciesConfig, storedAgentConfig?.auto_set_policies);

  /** Checks if the temporary companion auto set policy exists */
  const isFeatureFlagAutoSetPolicyDeprecated =
    featureFlags &&
    Object.keys(featureFlags).length > 0 &&
    featureFlags["companion-mode-enabled"] &&
    featureFlags["deprecate-feature-flag-auto-set-policies"];

  return (
    <CardBasedPage
      title={t("Agent Downloads")}
      breadcrumbs={[
        { title: t("Configuration"), isActive: false },
        { title: t("Agent Downloads"), isActive: true },
      ]}
    >
      <Box>
        <Box p="sp24">
          <Box mb="48">
            <SectionHeading title={t("Linewize Connect Downloads")} />
            <Flex px="sp12" py="sp16" direction="column" gap="sp16">
              <InlineNotification
                notificationDescription={
                  <>
                    Please check that your devices meet the{" "}
                    <Link
                      textDecoration="underline"
                      href="https://help.linewize.com/hc/en-gb/articles/10051669060508"
                      isExternal={true}
                      color="none"
                    >
                      Linewize Connect Minimum Requirements
                    </Link>
                  </>
                }
                status="info"
              />
              <Text>Select the download that matches your devices. </Text>

              <Flex justifyContent="flex-start" flexDirection="row" gap="sp64" alignItems="stretch">
                <Box backgroundColor="neutrals.20" pt="20px" pb="20px" pl="30px" pr="30px" borderRadius="md">
                  <Img mx="auto" maxW="55px" maxH="55px" src={WindowsLogo} backgroundColor="white" p="15" borderRadius="md" />

                  <Button
                    mt="sp12"
                    variant="primary"
                    display="block"
                    width="100%"
                    onClick={() => window.open("https://qoria-mza-installers.linewize.net/Connect%20Installer.msi", "_blank")}
                  >
                    Download for Windows (.msi)
                    <br />
                    For school IT admins
                  </Button>
                  <Button
                    mt="sp12"
                    variant="primary"
                    display="block"
                    width="100%"
                    onClick={() => window.open("https://qoria-mza-installers.linewize.net/Connect%20Installer.exe", "_blank")}
                  >
                    Download for Windows
                  </Button>
                </Box>
                <Box backgroundColor="neutrals.20" pt="20px" pb="20px" pl="30px" pr="30px" borderRadius="md">
                  <Img mx="auto" maxW="55px" maxH="55px" src={MacLogo} backgroundColor="white" p="15" borderRadius="md" />

                  <Button
                    mt="sp12"
                    variant="primary"
                    display="block"
                    width="100%"
                    onClick={() => window.open("https://qoria-mza-installers.linewize.net/Connect%20Installer.pkg", "_blank")}
                  >
                    Download for Mac (.pkg)
                    <br />
                    For school IT admins
                  </Button>
                  <Button
                    mt="sp12"
                    variant="primary"
                    display="block"
                    width="100%"
                    onClick={() => window.open("https://qoria-mza-installers.linewize.net/Connect%20Installer.app.zip", "_blank")}
                  >
                    Download for Mac
                  </Button>
                </Box>
                <Box backgroundColor="neutrals.20" pt="20px" pb="20px" pl="30px" pr="30px" borderRadius="md">
                  <Img mx="auto" maxW="55px" maxH="55px" src={ChromebookLogo} backgroundColor="white" p="15" borderRadius="md" />

                  {featureFlags && Object.keys(featureFlags).length > 0 && (
                    <>
                      <Button
                        mt="sp12"
                        variant="primary"
                        display="block"
                        width="100%"
                        // eslint-disable-next-line
                        onClick={() => copyToClipboard(process.env.REACT_APP_LINEWIZE_CHROME_EXTENSION_ID as string)}
                      >
                        Copy Extension ID
                      </Button>

                      <Button
                        mt="sp12"
                        variant="primary"
                        display="block"
                        width="100%"
                        // eslint-disable-next-line
                        onClick={() => copyToClipboard(`https://download.qoria.com/browser/${SessionStore.getSelectedDevice()}`)}
                      >
                        Copy Download URL
                      </Button>

                      <Button
                        mt="sp12"
                        variant="primary"
                        display="block"
                        width="100%"
                        // eslint-disable-next-line
                        onClick={() =>
                          copyToClipboard(
                            JSON.stringify({
                              configuration: {
                                Value: {
                                  // eslint-disable-next-line
                                  region: getRegion(SessionStore.getSelectedRegion()),
                                  // eslint-disable-next-line
                                  applianceId: SessionStore.getSelectedDevice(),
                                  preSharedKey: agentAuth.key,
                                },
                              },
                            })
                          )
                        }
                      >
                        Copy Managed Config
                      </Button>
                    </>
                  )}
                </Box>
              </Flex>
            </Flex>
          </Box>

          {isFeatureFlagAutoSetPolicyDeprecated && (
            <Box>
              <SectionHeading title={t("Automated Browser Policy")} />
              <Flex px="sp12" py="sp16" direction="column" gap="sp16">
                <Text>
                  Allow Connect for Windows to{" "}
                  <Link
                    textDecoration="underline"
                    href="https://help.linewize.com/hc/en-gb/articles/13490340432412-Deploying-Linewize-Connect-for-Windows-using-Automated-Browser-Policy"
                    isExternal={true}
                    color="none"
                  >
                    install browser extensions
                  </Link>
                </Text>

                <InlineNotification
                  notificationDescription={
                    <>
                      <Link
                        textDecoration="underline"
                        href="https://help.linewize.com/hc/en-gb/articles/13757895055900-Linewize-Companion-Mode-Guide"
                        isExternal={true}
                        color="none"
                      >
                        Turn on Companion Mode
                      </Link>{" "}
                      for Windows to install the policies below
                    </>
                  }
                  status="info"
                />

                <FormControl mb="sp16" display="flex" flexDirection="row" alignItems="center">
                  <Checkbox
                    id="companion"
                    data-testid="companion-checkbox"
                    isChecked={autoSetPoliciesConfig?.companion?.enabled ?? false}
                    onChange={handleAgentConfigChange}
                  />
                  <FormLabel htmlFor="enabled">Deploy Companion Mode Policy</FormLabel>
                </FormControl>

                <FormControl mb="sp16" display="flex" flexDirection="row" alignItems="center">
                  <Checkbox
                    id="content_aware"
                    data-testid="content_aware-checkbox"
                    isChecked={autoSetPoliciesConfig?.content_aware?.enabled ?? false}
                    onChange={handleAgentConfigChange}
                  />
                  <FormLabel htmlFor="enabled">
                    Deploy Content Aware Policy
                    <Tag size="compact" variant="orange" tagLabel={t("Licensed Feature")} />
                  </FormLabel>
                </FormControl>
              </Flex>
            </Box>
          )}
        </Box>
        {isFeatureFlagAutoSetPolicyDeprecated && (
          <Box p="sp24" backgroundColor="brand.100" borderBottomRadius="sm">
            <Button variant="primary" disabled={loading || saving || !hasChanges} onClick={handleSubmit}>
              {saving ? t("Saving..") : hasChanges ? t("Save") : t("Saved")}
            </Button>
          </Box>
        )}
      </Box>
    </CardBasedPage>
  );
};

export default MobileAgentDownloads;

import { create } from "zustand";
import { jqXHR, ResponseError } from "../types/Api";
import Api from "../utils/Api";
import { AgentConfig } from "../types/AgentConfig";

export const AGENT_CONFIG_URL = "/config/ajax/fzagentconfig";

export interface AgentConfigStore {
  config: AgentConfig;
  getOrFetch: () => Promise<AgentConfig>;
  fetch: () => Promise<AgentConfig>;
  save: (config: AgentConfig) => Promise<AgentConfig>;
  reset: () => void;
  set: (config: AgentConfig) => void;
}

const synchronized: { fetchResult?: Promise<AgentConfig> } = {};

/**
 * Gets Agent config from the store, or if the store is empty, then fetches
 * the config from Config Management API first.
 *
 * @returns agent config promise.
 */
export const useAgentConfigStore = create<AgentConfigStore>((set, get) => ({
  config: {} as AgentConfig,

  getOrFetch: async (): Promise<AgentConfig> => {
    let config = get().config;
    if (Object.keys(config).length > 0) {
      return config;
    }

    if (synchronized.fetchResult) {
      return await synchronized.fetchResult;
    }

    synchronized.fetchResult = get().fetch();
    config = await synchronized.fetchResult;
    synchronized.fetchResult = undefined;

    return config;
  },

  fetch: async (): Promise<AgentConfig> => {
    let config,
      statusCode = 200;

    await Api.getAsync(AGENT_CONFIG_URL).then(
      (response: AgentConfig) => (config = response),
      (reason: jqXHR) => (statusCode = reason?.status)
    );

    if (!config || statusCode !== 200) {
      throw new ResponseError("Failed to fetch mobile agent Config", statusCode);
    }

    set({ config });

    return config;
  },

  set: (config: AgentConfig): void => {
    set({ config });
  },

  save: async (config: AgentConfig): Promise<AgentConfig> => {
    let saved: AgentConfig | undefined,
      statusCode = 200;

    await Api.postAsync(AGENT_CONFIG_URL, config).then(
      () => (saved = { ...get().config, ...config }),
      (reason: jqXHR) => (statusCode = reason?.status)
    );

    if (!saved || statusCode !== 200) {
      throw new ResponseError("Failed to save mobile agent Config", statusCode);
    }

    set({ config: saved });

    return saved;
  },

  reset: () => {
    set({ config: undefined });
  },
}));
